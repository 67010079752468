@font-face {
  font-family: baloonR;
  src: url("./assets/fonts/BalooChettan2-Regular.ttf");
}
@font-face {
  font-family: baloonB;
  src: url("./assets/fonts/BalooChettan2-SemiBold.ttf");
}

h1,h3,h2,h4,h6{
  font-family: baloonB!important;
  color: #354f41!important;
}
p{
  font-family: baloonR!important;
  color: #354f41!important;
  margin: 0px;
}
li{
  color: #354f41!important;
}
body,button {
  margin: 0;
  font-family: baloonR;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.ocean { 
  height: 5%;
  width:100%;
  position:absolute;
  bottom:0;
  left:0;
  background: #015871;
}

.wave {
  background: url(https://s3-us-west-2.amazonaws.com/s.cdpn.io/85486/wave.svg) repeat-x; 
  position: absolute;
  top: -198px;
  width: 6400px;
  height: 198px;
  animation: wave 7s cubic-bezier( 0.36, 0.45, 0.63, 0.53) infinite;
  transform: translate3d(0, 0, 0);
}
.temp { 
  height: 100%;
  width:100%;
  position:absolute;
  bottom:0;
  left:0;
  background: linear-gradient(180deg,#fc6565,#e43030);
}
.wave:nth-of-type(2) {
  top: -175px;
  animation: wave 7s cubic-bezier( 0.36, 0.45, 0.63, 0.53) -.125s infinite, swell 7s ease -1.25s infinite;
  opacity: 1;
}

@keyframes wave {
  0% {
    margin-left: 0;
  }
  100% {
    margin-left: -1600px;
  }
}

@keyframes swell {
  0%, 100% {
    transform: translate3d(0,-25px,0);
  }
  50% {
    transform: translate3d(0,5px,0);
  }
}

/* width */
*::-webkit-scrollbar {
  width: 12px;
  height: 10px;
}

/* Track */
*::-webkit-scrollbar-track {
  background: #ffffff;
}

/* Handle */
*::-webkit-scrollbar-thumb {
  background: #275557b6;
  background: #8bab50 ;
border-radius: 5px;
}

/* Handle on hover */
*::-webkit-scrollbar-thumb:hover {
  background: #354f41;
}
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 20px;
}

.switch input { 
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 0px;
  bottom: -2px;
  background-color: #8bab50;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider {
  background-color: #354f41;
}

input:focus + .slider {
  box-shadow: 0 0 1px #354f41;
}

input:checked + .slider:before {

  transform: translateX(36px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}





input:focus-within {
  outline: none;
}
.chat::-webkit-scrollbar-thumb {

  background: #8bab50 ;
border-radius: 5px;
}

/* Handle on hover */
.chat::-webkit-scrollbar-thumb:hover {
  background: #354f41;
}
.chat::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

/* Track */
.chat::-webkit-scrollbar-track {
  background: whitesmoke;
}

/* smartphones, touchscreens */
@media (hover: none) and (pointer: coarse) {
  form::-webkit-scrollbar-thumb {
     background: #275557b6;
     background: #ab5350 ;
   border-radius: 5px;
   }
   
   form::-webkit-scrollbar {
     width: 0px;
     height: 10px;
   }
 
   .chat::-webkit-scrollbar-thumb {
     background: #275557b6;
     background: #ab5350 ;
   border-radius: 5px;
   }
   
   .chat::-webkit-scrollbar {
     width: 0px;
     height: 10px;
   }
   .TabsHolder::-webkit-scrollbar {
    width: 0px;
    height: 0px;
    display: none;
  }
 }

 